import React from "react"
import { graphql } from 'gatsby';
import { css } from "@emotion/react"
import Layout from "~components/Layout"
import Seo from "~components/Seo"
import { breakpoints } from '~styles/global'
import MakerBlock from "~components/MakerBlock"
import CloudButton from "~components/CloudButton"

const Makers = ( data ) => {
  const makers = data.data.allSanityMaker.nodes
  const { mobile } = breakpoints

  const makerBlockCss = css`
    margin-bottom: 10rem;
    ${mobile}{
      margin-bottom: 8rem;
    }
    &:first-of-type{
      margin-top: 6rem;
      ${mobile}{
        margin-top: 4rem;
      }
    }
  `

  return (
    <>
      <Seo
        title={"Makers"}
      />  
      {makers.map((maker, i) => (
        <MakerBlock
          heading={maker?.title}
          to={maker?.slug?.current}
          shortDesc={maker?._rawContent?.shortText}
          buttonText={maker?._rawContent?.shortName}
          image={maker?._rawContent?.headerImage}
          primary={false} 
          css={makerBlockCss}
          reverse={i%2 === 0}
          key={maker.title}
        />
      ))}
      <CloudButton to="/shop" css={css`margin-bottom: 50px;`}>
        All products
      </CloudButton>
    </>
  )
}

export const query = graphql`
  query{
    allSanityMaker {
      nodes {
        _rawContent
        title
        slug {
          current
        }
      }
    }
  }
`

export default Makers
