import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import useSiteSettings from "~utils/useSiteSettings"

const Seo = ({ description, lang, meta, title, image }) => {

  const {siteTitle, siteMetaDescription, siteSocialImage } = useSiteSettings()

  const metaDescription = description || siteMetaDescription
  const metaImage = image || siteSocialImage?.asset.url
  const defaultTitle = siteTitle

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title !== defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
				{
					name: 'facebook-domain-verification',
					content: 'zzq1twvmbxtyk32xu0xvr9tafqdji6'
				},
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default Seo
